import React, { useState, useEffect } from "react"
import { graphql, navigate } from 'gatsby'
import Helmet from 'react-helmet'
import HeadingBar from '../components/HeadingBar'
import Workout from '../components/Workout'
import RepeatTimer from '../components/RepeatTimer'
import { Loading, Card, Input, Button, Select, Spacer, Checkbox, Toggle, Tooltip } from '@geist-ui/react'
import { Search, Info, Edit, ChevronLeft, PlayCircle, Watch } from '@geist-ui/react-icons'

import Spotify from '../../static/assets/Spotify.png'
import Monday from '../../static/assets/Monday.png'
import Tuesday from '../../static/assets/Tuesday.png'
import Wednesday from '../../static/assets/Wednesday.png'
import Thursday from '../../static/assets/Thursday.png'
import Friday from '../../static/assets/Friday.png'
import Saturday from '../../static/assets/Saturday.png'
import Sunday from '../../static/assets/Sunday.png'

import { StaticImage } from 'gatsby-plugin-image'

import SwiperCore, { Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'

SwiperCore.use([Pagination]);

var today = new Date();
var dd = today.getDate();
var mm = today.getMonth()+1;
var yyyy = today.getFullYear();
if(dd<10){
  dd='0'+dd
} 
if(mm<10){
  mm='0'+mm
} 

today = yyyy+'-'+mm+'-'+dd;

 const weekdays = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
 let currentday = weekdays[new Date().getDay()];
 const shortdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']

const TrainerWorkouts = ({data}) => {

   var days = {
    Mon: 'Monday',
    Tue: 'Tuesday',
    Wed: 'Wednesday',
    Thu: 'Thursday',
    Fri: 'Friday',
    Sat: 'Saturday',
    Sun: 'Sunday'
  }

  var months = [
    'Jan 2023',
    'May 2023',
    'Jun 2023',
    'Jul 2023',
    'Aug 2023',
    'Sep 2023',
    'Oct 2023',
    'Nov 2023',
    'Dec 2023',
    'Jan 2024',
    'Nov 2023',
    'May 2023',
    'Nov 2024',
    'Dec 2024',
    'Jan 2025'
  ]

let currentDate = new Date();
let currentmonth = null;
let currentMonthIndex = 0;

for (let i = months.length - 1; i >= 0; i--) {
    const monthDate = parseMonth(months[i]);
    if (monthDate <= currentDate) {
        currentmonth = months[i];
        currentMonthIndex = i;
        break;
    }
}

const [style, setStyle] = useState()
const [format, setFormat] = useState()
const [search, setSearch] = useState()
const [showworkout, setShowWorkout] = useState()
const [workout, setWorkout] = useState({})
const [month, setMonth] = useState(months[currentMonthIndex])
const [corporate, setCorporate] = useState()
const [day, setDay] = useState(currentday)
const [selectedslide, setSelectedSlide] = useState(shortdays.indexOf(currentday))
const [showtimer, setShowTimer] = useState()
const [monthindex, setMonthIndex] = useState(currentMonthIndex)
const [selectedCoach, setSelectedCoach] = useState()

const workouts = data.allSupabaseWorkout.nodes

function parseMonth(monthString) {
    const [month, year] = monthString.split(" ");
    return new Date(`${month} 1, ${year}`);
}

const styles = [
   {"Boxer": "Mon"},
   {"Puncher Lower": "Tue"},
   {"Boxer Puncher": "Wed"},
   {"Puncher Upper": "Thu"},
   {"Counter Puncher": "Fri"},
   {"Brawler": "Sat"},
   {"Sunday School": "Sun"}
 ];
 
 function getDayByStyle(style) {
    const foundStyle = styles.find(item => item.hasOwnProperty(style));
    return foundStyle ? foundStyle[style] : null;
 };

const FilteredWorkouts = workouts.filter(function (e) {
  if (style) {
    return e.style === style;
  }
  else return e;
}).filter(function (e) {
  if (format) {
    return e.type === format;
  }
  else return e.type !== 'Personal Training';
}).filter(function (e) {
  if (corporate === 'Calabasas') {
    return e.title.includes('||') || (e.location && e.location.includes('Calabasas'));
  }
  else if (corporate === 'Coaches') {
    return e.location && e.location.includes('Coaches') && e.coach_id && (!selectedCoach || e.coach_name === selectedCoach);;
  }
  else return !e.title.includes('||') && (e.location ? e.location.includes('Box in Box') : true);
}).filter(function (e) {
  if (search) {
    return e.title.toLowerCase().includes(search.toLowerCase());
  }
  else return e;
}).filter(function (e) {
  if (month && !e.location.includes('Coaches')) {
    return e.date && new Date(e.date).toUTCString().includes(month.split(" ")[0]) && new Date(e.date).toUTCString().includes(month.split(" ")[1]);
  }
  else return e;
}).filter(function (e) {
  if (day) {
    if (e.date) {
      return new Date(e.date).toUTCString().includes(day);
    }
    else {
      return getDayByStyle(e.style) === day;
    }
  }
  else return e;
}).sort((a, b) => {
  const dateA = a.date ? new Date(a.date) : null;
  const dateB = b.date ? new Date(b.date) : null;
  if (dateA && dateB) {
    if (dateA.toUTCString().includes(currentmonth) && dateB.toUTCString().includes(currentmonth)) {
      return dateA - dateB;
    }
    return dateB - dateA;
  }
  if (dateA) return -1;
  if (dateB) return 1;
  return 0;
});

const styleHandler = val => setStyle(val)
const formatHandler = val => setFormat(val)

const coaches = [...new Set(workouts
  .map(workout => workout.coach_name)
  .filter(coach_name => coach_name)
)];

  return (
    <>
      <Helmet>
        <title>Trainer Workouts - Craft Boxing</title>
        <meta name="robots" content="noindex" data-react-helmet="true" />
      </Helmet>
      <HeadingBar noimage="true" />
      {!showworkout && <div className="spotify-buttons home left">
          <Watch onClick={() => (setShowWorkout(true), setShowTimer(true))} />
      </div>}
      {!showworkout && <div className="day-swiper">
             <Swiper
                spaceBetween={20}
                breakpoints={{
                  1300: {
                    slidesPerView: 4,
                    slidesPerGroup: 1
                  },
                  900: {
                    slidesPerView: 3,
                    slidesPerGroup: 1
                  },
                  700: {
                    slidesPerView: 2,
                    slidesPerGroup: 1
                  }
                }}
                pagination={{clickable: true}}
                slidesPerView={1}
                initialSlide={selectedslide}
                onSlideChange={(e) => setDay(shortdays[e.activeIndex])}
              >
                <SwiperSlide onClick={() => (setDay('Mon'), setSelectedSlide(0), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Monday}
                        className={!day ? 'all' : day === 'Mon' ? 'selected' : 'not-selected'}
                        alt="Boxer Mondays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Tue'), setSelectedSlide(1), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Tuesday}
                        className={!day ? 'all' : day === 'Tue' ? 'selected' : 'not-selected'}
                        alt="Puncher Tuesdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Wed'), setSelectedSlide(2), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Wednesday}
                        className={!day ? 'all' : day === 'Wed' ? 'selected' : 'not-selected'}
                        alt="Boxer Puncher Wednesdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Thu'), setSelectedSlide(3), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Thursday}
                        className={!day ? 'all' : day === 'Thu' ? 'selected' : 'not-selected'}
                        alt="Counter Puncher Thursdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Fri'), setSelectedSlide(4), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Friday}
                        className={!day ? 'all' : day === 'Fri' ? 'selected' : 'not-selected'}
                        alt="Puncher Fridays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Sat'), setSelectedSlide(5), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Saturday}
                        className={!day ? 'all' : day === 'Sat' ? 'selected' : 'not-selected'}
                        alt="Brawler Saturdays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
                 <SwiperSlide onClick={() => (setDay('Sun'), setSelectedSlide(6), document.getElementById("workouts").scrollIntoView({behavior: "smooth"}))}>
                  <img
                        src={Sunday}
                        className={!day ? 'all' : day === 'Sun' ? 'selected' : 'not-selected'}
                        alt="Rhythm Sundays"
                        style={{
                          objectFit:'cover'
                        }}
                   />
                 </SwiperSlide>
               </Swiper>
      </div>}
      <div className="workout-container" id="workout-container">
      {!showworkout && <div style={{padding: '0 5%'}}>
        <Spacer y={.5} />
        {corporate !== 'Coaches' &&
          <div className="selects">
            <Select size="large" width="50%" placeholder="Class Type" value={format} onChange={formatHandler}>
                <Select.Option value="">All Types</Select.Option>
                <Select.Option value="Craft Boxing">Craft Boxing</Select.Option>
                <Select.Option value="Fundamentals">Fundamentals</Select.Option>
                <Select.Option value="Bagwork">Bagwork</Select.Option>
                <Select.Option value="Bodywork">Bodywork</Select.Option>
                <Select.Option value="Shadowboxing">Shadowboxing</Select.Option>
                <Select.Option value="Mittwork">Mittwork</Select.Option>
                <Select.Option value="Roadwork">Roadwork</Select.Option>
                <Select.Option value="Strength">Strength</Select.Option>
                <Select.Option value="Brawl">Brawl</Select.Option>
                <Select.Option value="Bagwork Plus">Bagwork Plus</Select.Option>
                <Select.Option value="Personal Training">Personal Training</Select.Option>
                <Select.Option value="Other">Other</Select.Option>
              </Select>
              {/*<Select size="large" width="50%" placeholder="Style" value={style} onChange={styleHandler}>
                <Select.Option value="">Any Style</Select.Option>
                <Select.Option value="Boxer">Boxer</Select.Option>
                <Select.Option value="Puncher">Puncher</Select.Option>
                <Select.Option value="Brawler">Brawler</Select.Option>
                <Select.Option value="Counter Puncher">Counter Puncher</Select.Option>
                <Select.Option value="Boxer Puncher">Boxer Puncher</Select.Option>
                <Select.Option value="Sunday School">Sunday School</Select.Option>
              </Select>*/}
               <Select size="large" width="50%" clearable={true} value={month + "-" + monthindex} placeholder="Month" 
                  onChange={val => (setMonth(val.split("-")[0]), setMonthIndex(val.split("-")[1]))}>
                  <Select.Option value="">All Months</Select.Option>
                  {months.map((mon, index) => (
                     <Select.Option value={mon + "-" + index}>Month {index + 1}</Select.Option>
                  ))}
               </Select>
           </div>
          }
         <Spacer y={.5} />
          <div id="workouts">
          {day && <>
            <Spacer y={1} />
            <div style={{width:'200px', margin: '0 auto 30px auto'}}>
              <Select placeholder="Select Location" width="100%" value={corporate} onChange={(val) => setCorporate(val)}>
                <Select.Option value="Calabasas">Calabasas</Select.Option>
                <Select.Option value="">Box in Box</Select.Option>
                <Select.Option value="Coaches">Coaches</Select.Option>
              </Select>
             </div>
           {corporate === 'Coaches' && <>
            <div className="selects" style={{marginTop:'20px'}}>
              <Select size="large" width="50%" placeholder="Class Type" value={format} onChange={formatHandler}>
                  <Select.Option value="">All Types</Select.Option>
                  <Select.Option value="Craft Boxing">Craft Boxing</Select.Option>
                  <Select.Option value="Bagwork">Bagwork</Select.Option>
                  <Select.Option value="Bodywork">Bodywork</Select.Option>
                  <Select.Option value="Mittwork">Mittwork</Select.Option>
                  <Select.Option value="Roadwork">Roadwork</Select.Option>
                  <Select.Option value="Strength">Strength</Select.Option>
                  <Select.Option value="Personal Training">Personal Training</Select.Option>
                </Select>
                 <Select size="large" width="50%" clearable={true} value={selectedCoach} placeholder="Select Coach" 
                        onChange={val => setSelectedCoach(val)}>
                  <Select.Option value="">All Coaches</Select.Option>
                  {coaches.map(coach => (
                     <Select.Option key={coach} value={coach}>{coach}</Select.Option>
                  ))}
                </Select>
             </div>
             <div style={{marginTop:'20px'}}>
               <Input size="medium" width="100%" iconRight={<Search />} placeholder="Search By Title" value={search} onChange={(e) => setSearch(e.target.value)} />
             </div>
            </>}
            {FilteredWorkouts.length < 1 && <p className="empty centered">There are no workouts that match your search.</p>}
            {FilteredWorkouts.length > 0 && FilteredWorkouts.map((workout) => (
              <Card className={new Date(workout.date).toUTCString().includes(currentmonth) ? 'featured' : ''} onClick={() => (setWorkout(workout), setShowWorkout(true))}>
              <div className="flex">
                <span>
                  {workout.date ? (
                    workout.type === 'Brawl' 
                      ? new Date(workout.date).toUTCString().split(" ")[2] + " " + new Date(workout.date).toUTCString().split(" ")[1]
                      : monthindex 
                        ? 'Month ' + (parseInt(monthindex) + 1) 
                        : months.includes(new Date(workout.date).toUTCString().split(" ")[2] + " " + new Date(workout.date).toUTCString().split(" ")[3]) 
                          ? 'Month ' + (months.lastIndexOf(new Date(workout.date).toUTCString().split(" ")[2] + " " + new Date(workout.date).toUTCString().split(" ")[3]) + 1)
                          : new Date(workout.date).toUTCString().split(" ")[2] + " " + new Date(workout.date).toUTCString().split(" ")[3]
                  ) : (
                    getDayByStyle(workout.style) || "No Date"
                  )}
                </span>

                {workout.type === 'Other' || workout.type === 'Personal Training' ? (
                  workout.type + " " + workout.title + " (" + workout.style + ")"
                ) : (
                  workout.date 
                    ? days[new Date(workout.date).toUTCString().split(", ")[0]] + ": " + workout.type
                    : workout.title + ": " + workout.type
                )}
                {workout.coach_name && <>
                 <span style={{marginLeft:'15px'}}> by {workout.coach_name}</span>
                </>}
              </div>
              <PlayCircle size={40} /></Card>
              ))}
           </>}</div>
      </div>}
      {showworkout && <>
        <div id="back" className="back" onClick={() => (setShowWorkout(), setWorkout(), setShowTimer())}><ChevronLeft size={20} /> Back to All</div>
        {!showtimer ? <Workout workout={workout} /> : <><Spacer y={2} /><RepeatTimer /></>}
      </>}
      </div>
    </>
  )
}

export default TrainerWorkouts

export const pageQuery = graphql`query workoutsQuery {
     allSupabaseWorkout {
          nodes {
            title
            description
            id
            created_at
            rounds
            headings
            warmuprounds
            warmupheadings
            type
            style
            video
            date
            location
            burner
            cooldown
            coach_id
            coach_name
       }
     }
  }
`
